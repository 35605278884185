
#mstr-theme {

	.mstr-profile-wrapper {
		display: block;
		width: 200px;
		height: 200px;
		overflow: hidden;
		margin: 0 auto 20px;
		border-radius: 100%;
		border: 6px solid color(white);
		@include boxShadow(16);

		img {
			width: 100%;
			height: auto;
		}
	}

	.mstr-portfolio-wrapper {
		.mstr-portfolio-wrapper-inner {
			padding: 20px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.mstr-portfolio--item {
				position: relative;
				@include span(12 of 12);
				height: 400px;
				margin: 0;
				margin-bottom: gutter(12);
				overflow: hidden;
				background: color(boulder);
				border-radius: 10px;
				
				@include susy-breakpoint($bp-md, $susy) {
					flex: auto;
					flex-basis: 0;
					margin-right: gutter();

					min-width: span(4 of 12);

					&:nth-child(3n) {
						margin-right: 0;
					}

					&.last,
					&:last-child {
						margin-right: 0;
					}

					.grid--3 & {
						min-width: span(4 of 12);

						&:nth-child(3n) {
							margin-right: 0;
						}
	
						&.last,
						&:last-child {
							margin-right: 0;
						}
					}

					.grid--4 & {
						min-width: span(3 of 12);

						&:nth-child(3n) {
							margin-right: gutter();
						}

						&:nth-child(4n) {
							margin-right: 0;
						}
	
						&.last,
						&:last-child {
							margin-right: 0;
						}
					}
				}

				a {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					width: 100%;
					height: 100%;
					text-indent: -9999px;
					background: rgba(0,0,0,0.5);
					overflow: hidden;
					transition: 0.3s ease;
				}

				&:hover {

					a {
						cursor: pointer;
						background: rgba(255,255,255,0.9);
					}

					.mstr-portfolio--info {
						opacity: 1;

						label, p {
							color: color(charcoal);
						}

						.fas,
						.fab,
						.far,
						.mstr-icons {
							color: color(charcoal);
						}
					}

					.btn {
						opacity: 1;
					}
				}

				.mstr-portfolio--info {
					@include center(true,true);
					z-index: 10;
					width: 100%;
					padding: 20px;
					opacity: 1;
					transition: 0.5s ease;
					pointer-events: none;


					@include susy-breakpoint($bp-md, $susy) {
						width: 75%;
					}

					label, p {
						color: color(white);
						transition: 0.3s ease;
					}

					label {
						margin: 15px auto 0;
						font-size: 1.5em;
						font-weight: bold;
					    line-height: 1.3em;
					}

					p {
						font-size: 18px;
						line-height: 1.3em;
						text-transform: none;
						margin: 0 auto;
					}

					img {
						display: inline-block;
						width: auto;
						height: auto;
					}

					.fas,
					.fab,
					.far,
					.mstr-icons {
						font-size: 48px;
						// color: color(primary);
						color: color(white);
						transition: 0.3s ease;
					}
				}

				.btn {
					position: absolute;
					bottom: 10px;
					left: 10px;
					right: 10px;
					opacity: 1;

					@include susy-breakpoint($bp-md, $susy) {
						opacity: 0;
					}
				}


				.mstr-portfolio--bg {
					@include objectFit;
				}
			}

		}
	}

	.mstr-logos-wrapper {
		.mstr-logos-wrapper-inner {
			text-align: center;
			max-width: $content_width;
			margin: 20px auto;

			&:hover {
				img {
					opacity: 0.2;
				}
			}
			
			img {
				display: inline-block;
				width: span(4 of 12);
				height: auto;
				transition: 0.3s ease;

				@include susy-breakpoint($bp-md, $susy) {
					@include gallery(3 of 12);
				}

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	

}