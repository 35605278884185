/// All colors in standard variable format.
///
/// @group color

$layout_width:  1400px;
$content_width:  780px;
$sidebar_width:  300px;
$mobilenav_width: 280px;


$colors: (
    // section--darks
	black: #000000,
	nero: #151515,
	charcoal: #232323,
	ashes: #333333,

	// Mids
	dusty: #999999,
	boulder: #7a7a7a,
	silver: #c0c0c0,
	alto: #eeeeee,

	// Lights
	white: #FFFFFF,
	whitesmoke: #f4f4f4,
	ghost: #f9f9f9,

	// Rose
	rose: #D5AA9A,
	rosegold: #DDBAB1,
	duststorm: #E5CCC9,
	bizzare: #EEDEDA,

	// Gold
	dough: #C4B392,
	sands: #D9CA9F,

	// Dust
	celeste: #D1D2CA,
	westar: #DCD9D2,
	feta: #E8EBE0,
	desertstorm: #F4F2EE,

	//Special

	bebe: #009999,
	peach: #ff9999,

	// Reds

	cardinal: #C92228,
	milano: #C21A01,
	fire: #cf4e1b,

	// Kickstarter

	kickstarter: #2BDE73,
	messanger: #0084ff,

	// NBC

	nbcred: #e84034,
	nbcblue: #15435c,
	nbcfade: #bfcace,

	// BOC 

	bocmustard: #644507,
	bocyellow: #D09300,
	bocblue: #005A74,
	lightyellow: #FBB910,
	darkblue: #004f61,
	lightblue: #008CAD,
	brightblue: #96B8C2,
	background: #DAE5E9,

	// NUI

	nuigreen: #95be31,
	nuiblue: #008d9b,

	// AS

	asprimary: #232323,
	assecondary: #dfdfdf,

	// WA

	waprimary: #6BCB99,
	wasecondary: #e7edeb,

	// AQ

	aqprimary: #3EA1AF,
	aqsecondary: #E1E7E9,
	aqtertiary: #043D4F,

	// Guidelines

	guideblue: #27d0a9,
	guideorange: #f1682a,

	// Mestre
	
	primaryghost: #fdf8ff,
	primarylight: #D5AA9A,
	primary: #C4B392,
	primarydark: #D9CA9F,
	primarydarker: #D9CA9F,

	secondaryghost: #f8f8f8,
	secondarylight: #f1f1f1,
	secondary: #232323,
	secondarydark: #121212,
	secondarydarker: #000000,

	tertiaryghost: #eeeeee,
	tertiary: #dddddd,
	tertiarydark: #cccccc,
	tertiarydarker: #757575,

);



// Box Shadows


.box-shadow-1dp {
	@include boxShadow(1);
}

.box-shadow-4dp {
	@include boxShadow(4);
}

.box-shadow-6dp {
	@include boxShadow(6);
}
.box-shadow-8dp {
	@include boxShadow(8);
}

.box-shadow-12dp {
	@include boxShadow(12);
}

.box-shadow-16dp {
	@include boxShadow(16);
}
.box-shadow-24dp {
	@include boxShadow(24);
}

// Text Shadows


.text-shadow-1dp {
	@include boxShadow(1);
}

.text-shadow-4dp {
	@include boxShadow(4);
}

.text-shadow-6dp {
	@include boxShadow(6);
}
.text-shadow-8dp {
	@include boxShadow(8);
}

.text-shadow-12dp {
	@include boxShadow(12);
}

.text-shadow-16dp {
	@include boxShadow(16);
}
.text-shadow-24dp {
	@include boxShadow(24);
}

