#mstr-theme {

	// Logo

	.mstr-logo-wrapper {

		.mstr-logo-wrapper-inner {
			padding: 0;
			
			h1 {
				font-family: 'Playfair Display';
				font-size: 2.8em;
				line-height: 1.2;
				text-align: center;
				font-weight: bold;
				letter-spacing: -3px;
				color: color(fire);

				&:before,
				&:after {
					display: none;
				}

				span {
					display: block;
					// opacity: 0.5;
					font-family: 'Raleway';
					font-size: 0.23em;
					letter-spacing: 2px;
					text-transform: uppercase;
				}
			}
		}

		
	}

	.logo {
		position: relative;
		font-family: 'Playfair Display';
		font-style: normal;
		font-size: 28px;
		font-weight: bold;
		color: color(primary);
		opacity: 1;
		letter-spacing: -1px;

		@include susy-breakpoint($bp-md, $susy) {
			color: color(white);
			opacity: 0.15;
		}

		&::before,
		&::after {
			content:'';
			display: none;
			position: absolute;
			@include center(false, true);
			height: 1px;
			width: 10px;
			vertical-align: middle;
			background: color(white);
		}

		&::before {
			left: -10px;
		}
		&::after {
			right: -10px;
		}
	}


}