#mstr-theme {

	&.boc-theme {

		.mstr-section-wrapper {
			.mstr-section-wrapper-inner {
				.sample {
					background: #fefcf8;
				}
			}
		}

		h1, h2, h3, h4, h5 {
			color: color(bocblue);

			&::before,
			&::after {
				border-bottom: 1px solid color(bocyellow);
			}
		}

		a {
			color: color(bocyellow);
		}

		strong {
			color: color(charcoal);
		}


		.icons {
		  color: color(bocyellow);
		}

		.section--dark {
			h1, h2, h3, h4, h5 {
				color: color(bocyellow);

				&::before,
				&::after {
					border-bottom: 1px solid color(white);
				}
			}
		}

		
		.color-palette-wrapper {
			text-align: center;
			
			.color-palette {
				&.bocblue {
					background: color(bocblue);
				}
				&.bocyellow{
					background: color(lightyellow);
				}
			}
		}


		//-------------------------------
		// BOC Animations
		//-------------------------------



		.animation {
			height: 120px;
			width: calc(2880px/24);;
			margin: 0;
			animation: animate-sprite 3s steps(24) infinite;

			&.animation-insertcard {
				background: url('../../assets/img/insertcard-sm.png');
			}
		
		}



		@keyframes animate-sprite {
			from{
				background-position: 0;
			}
			to{
				background-position: -2880px;
			}
		}

		



	}
}