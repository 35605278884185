#mstr-theme {

	&.aqwz-theme {
		h1, h2, h3, h4, h5 {
			color: color(aqprimary);

			&::before,
			&::after {
				border-bottom: 1px solid color(aqsecondary);
			}
		}

		a {
			color: color(primary);
		}

		strong {
			// color: color(aqprimary);
		}	
  
		.mstr-section-wrapper {
			.mstr-section-wrapper-inner {
				.sample {
					// background: color(aqsecondary);
				}
			}
		}

		.mstr-banner-wrapper {
			.mstr-banner-wrapper-inner {
				.banner__caption {
					strong {
						color: rgba(255,255,255,0.25);
					}
				}
			}
		}


	}

}