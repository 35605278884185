#mstr-theme {

	&.wa-theme {
		h1, h2, h3, h4, h5 {
			color: color(waprimary);

			&::before,
			&::after {
				border-bottom: 1px solid color(wasecondary);
			}
		}

		a {
			color: color(primary);
		}

		strong {
			color: color(waprimary);
		}
	
  
		.mstr-section-wrapper {
			.mstr-section-wrapper-inner {
				.sample {
					background: color(wasecondary);
				}
			}
		}


	}

}