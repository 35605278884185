#mstr-theme {

	&.as-theme {
		h1, h2, h3, h4, h5 {
			color: color(asprimary);

			&::before,
			&::after {
				border-bottom: 1px solid color(assecondary);
			}
		}

		a {
			color: color(primary);
		}

		strong {
			color: color(asprimary);
		}
	
  



	}

}