#mstr-theme {

	&.me-theme {
		h1, h2, h3, h4, h5 {
			color: color(secondary);

			&::before,
			&::after {
				border-bottom: 1px solid color(primary);
			}
		}

		strong {
			color: color(secondary);
		}

		// -------------------------------
		// Mestre Logo
		// -------------------------------

		.mstr-logo-wrapper {
			display: inline-block;
			text-align: center;
			margin: 40px auto 0;
	
			.mstr-logo-wrapper-inner {
	
			}
	
			i.mstr-icons {
				display: inline-block;
				font-size: 45px;
				line-height: 1;
				margin-bottom: 10px;
			}
	
			label {
				display: block;
				font-family: 'Playfair Display';
				text-transform: uppercase;
				letter-spacing: 5px;
				font-size: 16px;
				line-height: 1;
			}
	
		}

		// -------------------------------
		// Three Pillars
		// -------------------------------

		.mstr-three-pillars-wrapper {
			margin: 20px auto;
			max-width: $content_width;

			.mstr-three-pillars-wrapper-inner {
				display: flex;
				flex-wrap: wrap;
			}

			.pillar {
				position: relative;
				display: inline-block;
				width: 100%;
				margin-right: gutter();
				padding: 10px;
				
				@include susy-breakpoint($bp-md, $susy) {
					width: span(4 of 12);
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					.icon-wrapper {
						i {
							// color: color(sands);
							@include textShadow(12);
							transform: scale(1.1);
						}

						i.icon-crown {
							transform: scale(1);
						}
					}
				}

				.icon-wrapper {
					position: relative;
					width: 100px;
					height: 100px;
					margin: auto;

					i {
						position: absolute;
						top: 0px;
						left: 0px;
						z-index: 1;
						width: 100%;
						height: 100%;
						text-align: center;
						font-size: 100px;
						line-height: 100px;
						color: color(primary);
						@include textShadow(8);
						transition: 0.3s ease;
					}

					i.icon-crown {
						z-index: 0;
						color: rgba(0,0,0,0.05);
						text-shadow: none;
					}
				}

				label {
					display: inline-block;
					margin-top: 20px;
					text-align: center;
					text-transform: capitalize;
					font-family: "Playfair Display";
					font-size: 14px;
					font-style: italic;
					color: color(charcoal);
				}

				p {
					font-size: 14px;
					margin: 10px 0;
				}
			}
		}
		

		// -------------------------------
		// Stamps
		// -------------------------------

		.mstr-stamps-wrapper {
		
			.mstr-stamps-wrapper-inner {
				
			}

			.stamp {
				display: inline-block;
				position: relative;
				padding-left: 65px;
				text-align: left;
				border: 1px solid color(charcoal);

				.stamp__icon {
					position: absolute;
					top: 0;
					left: 0;
					width: 65px;
					height: 100%;
					padding: 0;
					text-align: center;
					line-height: 100%;
					border-right: 1px solid color(charcoal);

					.mstr-icons {
						color: color(charcoal);
						line-height: 3;
						vertical-align: middle;
					}
				}

				.stamp__info {
					font-family: "Roboto Condensed";
					text-transform: uppercase;
					text-align: left;
					letter-spacing: 2px;
					line-height: 2;
					color: color(charcoal);

					.stamp__info-primary {
						width: 100%;
						padding: 0 20px;
						font-size: 18px;
						border-bottom: 1px solid color(charcoal);
					}
					.stamp__info-secondary {
						padding: 0 80px 0 20px;
						font-size: 12px;
						line-height: 2.5;
					}
					.stamp__info-code {
						position: absolute;
						bottom: 0;
						right: 0;
						width: 60px;
						padding: 0 10px;
						text-align: center;
						font-size: 12px;
						line-height: 2.5;
						border-left: 1px solid color(charcoal);
					}
				}
			}
		}
  



	}

}