#mstr-theme {

	&.nui-theme {
		h1, h2, h3, h4, h5 {
			color: color(nuiblue);

			&::before,
			&::after {
				border-bottom: 1px solid color(nuigreen);
			}
		}

		a {
			color: color(nuigreen);
		}

		strong {
			color: color(charcoal);
		}
	
  



	}

}