#mstr-theme {

	&.nbc-theme {
		h1, h2, h3, h4, h5 {
			color: color(nbcblue);

			&::before,
			&::after {
				border-bottom: 1px solid color(nbcred);
			}
		}

		a {
			color: color(nbcred);
		}

		strong {
			color: color(charcoal);
		}


		//-------------------------------
		// NBC Account
		//-------------------------------

		.account-wrapper {
			overflow: hidden;
			height: 80px;
			min-width: 640px;

			.account-type {
				text-align: left;
				float: left;

				.inner {
					position: relative;
					top: auto;
					left: auto;
					font-size: 0;
					overflow: hidden;
					width: 610px;
					height: 80px;
					padding: 0;
					margin: 0;
					transition: 0.2s ease;
					transform: translate(0);

					&.collapsed {
						width: 200px;

						button.first {
							// margin-right: 10px;
							// text-align: left;
							// border-radius: 5px;

							i.icon-chevron-right {
								display: block;
							}
						}
					}
				}

				button {
					overflow: hidden;
				    height: 60px;
				    min-width: auto;
					margin: 10px 0px;
					padding: 0px 15px;
					font-size: 25px;
					color: color(nbcblue);
				 	text-align: left;
				 	text-transform: capitalize;
					border: 1px solid #979797;
					border-left: none;
					border-radius: 0px;
					background: color(white);
					box-shadow: 4px 4px 0px rgba(217, 224, 230, 1);

					&:hover {
						transform: scale(1);
					}

					&.active {
						width: 200px;
						margin-right: 10px;
						text-align: left;
						border-radius: 5px;

						span {
							display: block;
						}

						i.icon-chevron-left {display: block;}
						
					}

					&.first {
						border-radius: 5px 0px 0px 5px;
						border-left: 1px solid #979797
					}
					&.last {
						border-radius: 0px 5px 5px 0px;
					}

					span {
						color: #B53229;
					    font-size: 16px;
					    line-height: 26px;
					    margin-left: 10px;
					    vertical-align: middle;
					    display: none;
					    position: absolute;
					    top: 17px;
					    right: 10px;
					}

					i.icon-chevron-right {
						position: absolute;
						right: 15px;
						top: 20px;
						color: color(nbcred);
						display: none;
					}

				}
			}
			.account-balance {
				text-align: left;
				float: left;
				display: none;
				height: 80px;
				position: relative;
				margin-left: -4px;
			
				&.collapsed {
					
					.inner {
						width: 0;
					}


					button {
						display: none;
					  
						&.first {
							margin-top: 100px;
						}
						&.last {
							
						}
					}
				}

				&.active {
					.inner {
						width: 330px;
					}
				}

				.inner {
					position: relative;
					top: auto;
					left: auto;
					font-size: 0;
					height: 80px;
					width: 430px;
					padding: 0 150px 0 0;
					margin: 0;
					overflow: hidden;
					transition: 0.2s ease;
					transform: translate(0);
				}

				button {
					overflow: hidden;
					display: inline-block;
				    height: 60px;
				    width: 140px;
				    min-width: auto;
					margin: 10px 0;
					padding: 0px 15px;
					font-size: 16px;
				    line-height: 22px;
					color: color(nbcblue);
				 	text-align: left;
				 	text-transform: uppercase;
					border: 1px solid #979797;
					border-left: none;
					border-radius: 0;
					background: color(white);
					box-shadow: 4px 4px 0px rgba(217, 224, 230, 1);

					&:hover {
						transform: scale(1);
					}

					span {
						display: block;
						font-size: 24px;
						line-height: 24px;
						color: color(nbcblue);
					}
					i.icon-chevron-left {display: block;}

					// delete to revert back ----------------------

					&.first {
						border-left: 1px solid #979797;
					}

					&.last {

					}

					&.trigger {
						position: absolute;
						top: 0;
						right: 0;
						color: color(white);
						background: color(nbcblue);
						margin-right: 10px;
						border-radius: 0px 5px 5px 0px;
						font-size: 20px;
					}

					i.icon-chevron-left {
						position: absolute;
						left: 15px;
						top: 20px;
						color: color(nbcred);
						display: none;
					}



				}
			}

		}

		//-------------------------------
		// NBC Animation
		//-------------------------------

		.animation {
			position: relative;
			display: inline-block;
			width: 150px;
			height: 150px;
			padding: 0px;
			overflow: hidden;
			border-top: 2px solid #00374D;

			img {
				&.hand-grip,
				&.hand-nogrip {
					position: absolute;
					top: 70px;
					left: 72px;
				}
				&.hand-grip {
					z-index: 10;
				}


				&.hand-grip,
				&.hand-nogrip {
					&.take-item {
						-webkit-animation: take-item-grip 2.5s linear infinite;
						-moz-animation: take-item-grip 2.5s linear infinite;
						-ms-animation: take-item-grip 2.5s linear infinite;
						-o-animation: take-item-grip 2.5s linear infinite;
						animation: take-item-grip 2.5s linear infinite;
					}
					&.insert-item {
						-webkit-animation: insert-item-grip 2.5s linear infinite;
						-moz-animation: insert-item-grip 2.5s linear infinite;
						-ms-animation: insert-item-grip 2.5s linear infinite;
						-o-animation: insert-item-grip 2.5s linear infinite;
						animation: insert-item-grip 2.5s linear infinite;
					}
				}

				&.cash,
				&.card,
				&.check,
				&.checks,
				&.receipt,
				&.all-items {
					position: absolute;
					top: 40px;
					left: 20px;
					z-index: 5;

					&.insert-item {
						-webkit-animation: insert-item 2.5s linear infinite;
						-moz-animation: insert-item 2.5s linear infinite;
						-ms-animation: insert-item 2.5s linear infinite;
						-o-animation: insert-item 2.5s linear infinite;
						animation: insert-item 2.5s linear infinite;
					}

					&.take-item {
						-webkit-animation: take-item 2.5s linear infinite;
						-moz-animation: take-item 2.5s linear infinite;
						-ms-animation: take-item 2.5s linear infinite;
						-o-animation: take-item 2.5s linear infinite;
						animation: take-item 2.5s linear infinite;
					}
				}
			}
		}


		

		//-----------------------------------------------------------
		// object animation for take items
		//-----------------------------------------------------------


		@-webkit-keyframes take-item /* Safari and Chrome */ {
		  0% {
		    top: -100px;
		  }
		  25% {
		    top: -50px;
		  }
		  50% {
		    top: -50px;
		  }
		  75% {
		    top: 40px;
		  }
		  100% {
		    top: 40px;
		  }
		}
		@keyframes take-item {
		  0% {
		    top: -100px;
		  }
		  25% {
		    top: -50px;
		  }
		  50% {
		    top: -50px;
		  }
		  75% {
		    top: 40px;
		  }
		  100% {
		    top: 40px;
		  }
		}


		//-----------------------------------------------------------
		// hands animation for insert items
		//-----------------------------------------------------------

		@-webkit-keyframes insert-item-grip /* Safari and Chrome */ {
		  0% {
		    top: 75px;
		  }
		  25% {
		    top: 75px;
		  }
		  50% {
		    top: 0px;
		  }
		  75% {
		    top: 75px;
		  }
		  100% {
		    top: 75px;
		  }
		}
		@keyframes insert-item-grip {
		  0% {
		    top: 75px;
		  }
		  25% {
		    top: 75px;
		  }
		  50% {
		    top: 0px;
		  }
		  75% {
		    top: 75px;
		  }
		  100% {
		    top: 75px;
		  }
		}

		//-----------------------------------------------------------
		// object animation for take items
		//-----------------------------------------------------------

		@-webkit-keyframes take-item /* Safari and Chrome */ {
		  0% {
		    top: -100px;
		  }
		  25% {
		    top: -50px;
		  }
		  50% {
		    top: -50px;
		  }
		  75% {
		    top: 40px;
		  }
		  100% {
		    top: 40px;
		  }
		}
		@keyframes take-item {
		  0% {
		    top: -100px;
		  }
		  25% {
		    top: -50px;
		  }
		  50% {
		    top: -50px;
		  }
		  75% {
		    top: 40px;
		  }
		  100% {
		    top: 40px;
		  }
		}


		//-----------------------------------------------------------
		// object animation for insert items
		//-----------------------------------------------------------

		@-webkit-keyframes insert-item /* Safari and Chrome */ {
		  0% {
		    top: 40px;
		  }
		  25% {
		    top: 40px;
		  }
		  50% {
		    top: -50px;
		  }
		  75% {
		    top: -50px;
		  }
		  100% {
		    top: -100px;
		  }
		}
		@keyframes insert-item {
		  0% {
		    top: 40px;
		  }
		  25% {
		    top: 40px;
		  }
		  50% {
		    top: -50px;
		  }
		  75% {
		    top: -50px;
		  }
		  100% {
		    top: -100px;
		  }
		}


		//-------------------------------
		// NBC Animation - Sprite
		//-------------------------------


		.animation-sprite {
			width: calc(849px/3);
			height: 67px;
			margin: 0;
			border: none;
			animation: animate 1s steps(3) infinite;

			&.animation-language {
				background: url('../../assets/img/nbc-languages.jpg');
			}
			
		}



		@keyframes animate {
			from{
				background-position: 0;
			}
			to{
				background-position: -849px;
			}
		}





	}


	


}