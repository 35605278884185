#mstr-theme {


	.mstr-section-wrapper {
		.mstr-section-wrapper-inner {
			
			.sample {
				position: relative;
				display: block;
				width: 100%;
				padding: 20px;
				margin: 10px 0;
				background: color(ghost);
				border-radius: 5px;
				// overflow-x: scroll;

				.no-touch {
					pointer-events: none;
				}

				h1, h2, h3, h4 {
					display: inline-block;
					margin: 0;
					padding: 0 2px;
					text-transform: capitalize;


					&.highlight {
						position: relative;
						background: rgba(255,0,0,0.05);

						&:before,
						&:after {
							display: block;
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							margin-top: -5px;
							height: 30px;
							width: 0;
							border-left: 1px solid rgba(255,0,0,0.25);
						}

						&:after {
							left: 100%;
						}
					}

					&:before,
					&:after {
						display: none;
					}
				}

				p {
					margin: 1em 0;
				}

				img {
					display: block;
					width: 100%;
					height: auto;
				}

				&.sample--grid {

					@include susy-breakpoint($bp-md, $susy) {
						display: flex;
						flex-wrap: wrap;

					}
					
					img {
						display: block;
						padding: 5px;

						@include susy-breakpoint($bp-md, $susy) {
							display: inline-block;
							flex: auto;
							flex-basis: 0;
							min-width: span(3 of 12);
						}

						&.blank {
							display: none;
					
							@include susy-breakpoint($bp-md, $susy) {
								display: block;
							}
						}
					}
				}

				&.sample--inline {
					img {
						display: inline-block;
						width: auto;
						height: auto;
					}
				}

				&.sample--dark {
					background: color(charcoal);
				}

			}
		}
	}


	//-------------------------------
	// Color Palette
	//-------------------------------

	.color-palette-wrapper {
		text-align: center;

		.color-palette {
			display: inline-block;
			width: 50px;
			height: 50px;
			margin: 5px;
			background: #fff;
		}
	}

	//-------------------------------
	// Guidelines
	//-------------------------------

	.guidelines {
		position: relative;

		.guides {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;

			.guide {
				position: absolute;
				display: inline-block;
				padding: 5px 15px;
				font-size: 12px;
				line-height: 1.3em;
				color: color(white);
				background: color(charcoal);
				border-radius: 3px;

				&.height,
				&.width {
					background: color(guideorange);
				}

				&.padding-left,
				&.padding-right,
				&.padding-top,
				&.padding-bottom {
					background: color(guideblue);
				}

				&.height {
					&:before {
						position: absolute;
						top: -50%;
						left: -10px;
						content: '';
						display: block;
						border-left: 1px solid color(guideorange);
					}

					&:after {
						position: absolute;
						@include center(false,true);
						left: -5px;
						content: '';
						border-right: 5px solid color(guideorange);
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
					}

					&.height-60 {
						&:before {
							height: 60px;
						}
					}
				}


				&.width {
					&:before {
						position: absolute;
						left: -50%;
						bottom: -10px;
						content: '';
						display: block;
						border-bottom: 1px solid color(guideorange);
					}

					&:after {
						position: absolute;
						@include center(true,false);
						bottom: -5px;
						content: '';
						border-top: 5px solid color(guideorange);
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
					}

					&.width-120 {
						&:before {
							width: 120px;
						}
					}
				}

				&.padding-left,
				&.padding-right {
					&:before {
						position: absolute;
						left: 40%;
						top: -10px;
						content: '';
						display: block;
						border-bottom: 1px solid color(guideblue);
					}

					&:after {
						position: absolute;
						@include center(true,false);
						top: -5px;
						content: '';
						border-bottom: 5px solid color(guideblue);
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
					}

					&.padding-15 {
						&:before {
							width: 15px;
						}
					}
				}

				&.font {

					&:after {
						position: absolute;
						@include center(true,false);
						bottom: -5px;
						content: '';
						border-top: 5px solid color(charcoal);
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
					}
				}
			}
		}
	}


}